@import '@develos/next-library/dist/esm/index.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --colors-main-p1: #3490dc;
  --secondary-color: #ffed4a;
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
